<template>
    <v-row>
        <v-col cols="10" offset="1">
            <ValidationObserver ref="form">
                <v-card>
                    <v-card-text>
                        <ValidationProvider
                            :name="$t('knowledges.attributes.type')"
                            :rules="'required'"
                            v-slot="{ errors }"
                        >
                            <v-select
                                v-model="knowledge.type"
                                :items="availablesTypes"
                                :label="$t('knowledges.attributes.type')"
                                color="red"
                                :error-messages="errors[0]"
                            ></v-select>
                        </ValidationProvider>
                    </v-card-text>
                    <v-card-text>
                        <ValidationProvider
                            :name="$t('knowledges.attributes.name')"
                            :rules="'required'"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                :label="$t('knowledges.attributes.name')"
                                v-model="knowledge.name"
                                single-line
                                color="red"
                                :error-messages="errors[0]"
                            ></v-text-field>
                        </ValidationProvider>
                    </v-card-text>
                    <v-card-text>
                        <editor v-model="knowledge.description" />
                    </v-card-text>
                    <v-divider />
                    <v-card-text>
                        <knowledge-files
                            ref="knowledgeFiles"
                            :knowledge="knowledge"
                            :with-title="true"
                            :only-edit="true"
                            v-on:update-attribute="updateAttribute"
                        />
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="$router.go(-1)" v-text="$t('buttons.cancel')" />
                        <v-btn color="success" text @click="save" v-text="$t('buttons.save')" />
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import editor from '@/components/shared/editor';
import knowledgeFiles from './knowledge/files.vue';
import Clone from 'lodash/clone';

export default {
    name: 'knowledge-create',
    data: () => ({
        knowledge: {
            type: undefined,
            name: undefined,
            description: undefined,
            files: [],
        },
    }),
    mixins: [],
    components: { editor, knowledgeFiles },
    created() {},
    watch: {},
    computed: {
        availablesTypes() {
            return [
                { text: this.$t('lists.knowledgeType.tip'), value: 'tip' },
                { text: this.$t('lists.knowledgeType.question'), value: 'question' },
                { text: this.$t('lists.knowledgeType.note'), value: 'note' },
                { text: this.$t('lists.knowledgeType.link'), value: 'link' },
                { text: this.$t('lists.knowledgeType.video'), value: 'video' },
            ];
        },
    },
    methods: {
        ...mapActions(['createKnowledge']),
        updateAttribute({ name, value }) {
            if (name === 'files') {
                this.knowledge.files = this.knowledge.files.concat(value);
            }
            if (name === 'update-files') {
                this.knowledge.files = value;
            } else {
                this.knowledge[name] = value;
            }
        },
        async save() {
            const validate = await this.$refs.form.validate();

            if (validate) {
                // Send files before creating
                await this.$refs.knowledgeFiles.uploadFiles();

                let knowledge = Clone(this.knowledge);
                try {
                    await this.createKnowledge(knowledge);
                    this.$router.go(-1);
                } catch (error) {}
            }
        },
    },
};
</script>
